import React, {useRef, useState} from "react";
import shareIcon from "../assets/icons/share-icon.svg";
import Slider from "react-slick";
import carouselLeftArrow from "../assets/icons/carousel-left-arrow.svg";
import carouselRightArrow from "../assets/icons/carousel-right-arrow.svg";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

export default function PropertySectionTab({property}) {
    const sliderRef = useRef(null);
    const {t} = useTranslation();
    const [selectedOption, setSelectedOption] = useState("property");
    const {i18n} = useTranslation();

    const description = (i18n.language === 'it')
        ? property.propertyDescriptionIT
        : property.propertyDescriptionEN;
    const location = (i18n.language === 'it')
        ? property.locationDescriptionIT
        : property.locationDescriptionEN;
    const area = property.area?.toLocaleString('it-IT');
    const outdoorArea = property.outdoorArea?.toLocaleString('it-IT');
    const price = property.price?.toLocaleString('it-IT');
    const city = property.city;
    const reference = property.reference;
    const floorPlans = property.floorPlans;
    const propertyTypology = property.propertyTypology;

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Downtown – Property',
                text: 'Scopri le migliori case di lusso in vendita con Downtown. Ville esclusive, attici panoramici e residenze uniche in location prestigiose. Trova la tua casa da sogno oggi stesso.',
                url: window.location.href,
            })
                .then()
                .catch((error) => console.error('Errore durante la condivisione:', error));
        } else {
            console.log('La condivisione non è supportata in questo browser.');
        }
    };

    const options = [
        {value: "property", label: "properties.tabs.labels.property"},
        {value: "location", label: "properties.tabs.labels.location"},
        {value: "floorPlans", label: "properties.tabs.labels.floorPlans"},
    ];

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1,
        slidesPerRow: 1,
    };

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const Tab = ({label, active, onClick, addClass}) => (
        <button
            className={`font-raleway text-gray uppercase tracking-[1.8px] ${addClass}
      ${active ? '!font-bold' : '!hover:font-bold'}`}
            onClick={onClick}
        >
            {t(label)}
        </button>
    );

    const Tabs = ({tabs}) => {
        const [activeTab, setActiveTab] = useState(tabs[0].label);
        return (
            <div className="mt-[120px] px-10 xl:px-[298px]">
                <div className={`mb-20 flex justify-end`}>
                    {tabs?.map((tab) => (
                        <Tab
                            key={tab.label}
                            label={tab.label}
                            active={activeTab === tab.label}
                            addClass={tab.addClass}
                            onClick={() => setActiveTab(tab.label)}
                        />
                    ))}
                </div>
                <div className="mt-4">
                    {tabs.find((tab) => tab.label === activeTab)?.content}
                </div>
            </div>
        );
    };

    return (
        <div>
            <Helmet>
                <title>{`Downtown - ${t(`property.typologies.${property?.propertyTypology}`)} - ${property?.city}`}</title>
                <meta property="og:title"
                      content={`Downtown - ${t(`property.typologies.${property?.propertyTypology}`)} - ${property?.city}`}/>
                <meta property="og:description"
                      content='Scopri le migliori case di lusso in vendita con Downtown. Ville esclusive, attici panoramici e residenze uniche in location prestigiose. Trova la tua casa da sogno oggi stesso.'/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <div className="hidden md:block">
                <Tabs
                    tabs={[
                        {
                            label: 'properties.tabs.labels.property',
                            content:
                                <div
                                    className="max-w-[985px] font-raleway text-[18px] tracking-[0] text-gray font-normal"
                                    dangerouslySetInnerHTML={{
                                        __html: description
                                            ? description
                                            : `<h4 class='h4-primary'>${t('properties.noDescription')}</h4>`,
                                    }}>
                                </div>,
                            addClass: 'me-10'
                        },
                        {
                            label: 'properties.tabs.labels.location', content:
                                <div className="max-w-[985px] font-raleway text-[18px] tracking-[0] text-gray font-normal"
                                     dangerouslySetInnerHTML={{
                                    __html: location
                                        ? location
                                        : `<h4 class='h4-primary'>
                                            ${t('properties.noLocation')}
                                           </h4>`,
                                     }}>
                                </div>,
                            addClass: 'me-10'
                        },
                        {
                            label: 'properties.tabs.labels.floorPlans',
                            content: floorPlans?.length === 0
                                ?
                                <div>
                                    <h4 className={'h4-primary'}>
                                        {t('properties.noFloorPlans')}
                                    </h4>
                                </div>
                                :
                                <div>
                                    <Slider {...settings} ref={sliderRef}>
                                        {
                                            floorPlans?.map((item, index) => {
                                                return (
                                                    <div key={index}
                                                         className={`relative group md:p-[15px]`}>
                                                        <img src={item.path}
                                                             alt={`item-${index}`}
                                                             className="w-full h-[560px] md:h-[300px] lg:h-[500px] 2xl:h-[600px] object-cover"
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </Slider>
                                    <div className="flex justify-center mt-4">
                                        <button
                                            onClick={prevSlide}
                                            className="me-[10px]"
                                        >
                                            <img src={carouselLeftArrow} alt="prevSlide"/>
                                        </button>
                                        <button
                                            onClick={nextSlide}
                                        >
                                            <img src={carouselRightArrow} alt="nextSlide"/>
                                        </button>
                                    </div>
                                </div>
                        },
                    ]}
                />
                <div className="flex w-100 mt-[50px] px-10 xl:px-[298px]">
                    <div className="flex flex-col me-32">
                        <span className="font-raleway text-[18px] tracking-[1.8px] text-gray uppercase">
                            {t('properties.tabs.labels.area')}
                        </span>
                        <h4 className="h4-primary text-nowrap">
                            {area ? `${area} mq` : '-'}
                        </h4>
                    </div>
                    <div className="flex flex-col me-32">
                        <span className="font-raleway text-[18px] tracking-[1.8px] text-gray uppercase">
                            {t('properties.tabs.labels.outdoorArea')}
                        </span>
                        <h4 className="h4-primary text-nowrap">
                            {outdoorArea ? `${outdoorArea} mq` : '-'}
                        </h4>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-raleway text-[18px] tracking-[1.8px] text-gray uppercase">
                            {t('properties.tabs.labels.price')}
                        </span>
                        <h4 className="h4-primary text-nowrap">
                            {price ? `${price} €` : '-'}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="md:hidden px-10 flex flex-col">
                <div className="flex w-100 justify-end">
                    <img className="ms-4 mt-1" src={shareIcon} alt="share" onClick={handleShare}/>
                </div>
                <div className="flex flex-col mt-[18px]">
                    <span className={'font-serifDisplay text-[35px] text-lightGray capitalize'}>
                        {t(`property.typologies.${propertyTypology}`)}
                    </span>
                    <span
                        className="font-raleway text-left uppercase text-gray font-light tracking-[1.8px] w-full pb-10">
                            {city ?? ''}
                    </span>
                    <span
                        className="font-raleway text-left uppercase text-gray font-light tracking-[1.8px] w-full">
                            {reference}
                    </span>
                </div>
                <div className="mt-[68px] flex flex-col">
                    <div className="flex justify-between">
                        <div className="flex flex-col">
                            <span className="font-raleway text-[16px] tracking-[1.6px] uppercase text-lightGray">
                                {t('properties.tabs.labels.area')}
                            </span>
                            <h4 className="h4-primary mt-[5px] !lowercase text-nowrap">
                                {area ? `${area} mq` : '-'}
                            </h4>
                        </div>
                        <div className="flex flex-col">
                            <span className="font-raleway text-[16px] tracking-[1.6px] uppercase text-lightGray">
                            {t('properties.tabs.labels.outdoorArea')}
                            </span>
                            <h4 className="h4-primary mt-[5px] !lowercase text-nowrap">
                                {outdoorArea ? `${outdoorArea} mq` : '-'}
                            </h4>
                        </div>
                    </div>
                    <div className="mt-[85px] flex flex-col">
                        <span className="font-raleway text-[16px] tracking-[1.6px] uppercase text-lightGray">
                            {t('properties.tabs.labels.price')}
                        </span>
                        <h4 className="h4-primary mt-[5px] !lowercase text-nowrap">
                            {price ? `${price} €` : '-'}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="md:hidden px-10 flex flex-col mt-[68px]">
                <select
                    id="dynamic-select"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="w-full"
                >
                    {options?.map((option) => (
                        <option key={option.value} value={option.value}>
                            {t(option.label)}
                        </option>
                    ))}
                </select>
                <div className="mt-[60px]">
                    {selectedOption === "property" && (
                        <div
                            className="max-w-[985px] font-raleway text-[18px] tracking-[0] text-gray font-normal"
                            dangerouslySetInnerHTML={{
                                __html: description
                                    ? description
                                    : `<h6 class='h6-primary text-center'>${t('properties.noDescription')}</h6>`,
                            }}>
                        </div>
                    )}
                    {selectedOption === "location" && (
                        <div className="max-w-[985px] font-raleway text-[18px] tracking-[0] text-gray font-normal"
                             dangerouslySetInnerHTML={{
                                 __html: location
                                     ? location
                                     : `<h6 class='h6-primary text-center'>
                                            ${t('properties.noLocation')}
                                           </h6>`,
                             }}>
                        </div>
                    )}
                    {selectedOption === "floorPlans" && floorPlans?.length > 0 && (
                        <div>
                            <Slider {...settings} ref={sliderRef}>
                                {
                                    floorPlans?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                 className={`relative group md:p-[15px]`}>
                                                <img src={item.path}
                                                     alt={`item-${index}`}
                                                     className="w-full h-[560px] md:h-[300px] lg:h-[500px] 2xl:h-[600px] object-cover"
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </Slider>
                            <div className="flex justify-center mt-4">
                                <button
                                    onClick={prevSlide}
                                    className="me-[10px]"
                                >
                                    <img src={carouselLeftArrow} alt="prevSlide"/>
                                </button>
                                <button
                                    onClick={nextSlide}
                                >
                                    <img src={carouselRightArrow} alt="nextSlide"/>
                                </button>
                            </div>
                        </div>
                    )}
                    {selectedOption === "floorPlans" && floorPlans?.length === 0 && (
                        <h6 className={'h6-primary text-center'}>
                            {t('properties.noFloorPlans')}
                        </h6>
                    )}
                </div>
            </div>
        </div>
    )
}
