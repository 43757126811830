import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const customLanguageDetector = {
    name: 'customLanguageDetector',
    lookup() {
        const browserLanguage = navigator.language || (navigator.languages && navigator.languages[0]);
        if (browserLanguage.startsWith('it')) {
            return 'it';
        }
        return 'en';
    },
    cacheUserLanguage() {},
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
        detection: {
            order: ['customLanguageDetector', 'localStorage', 'cookie', 'navigator', 'htmlTag'],
            caches: ['localStorage', 'cookie'],
        },
    }).then();

i18n.services.languageDetector.addDetector(customLanguageDetector);

export default i18n;