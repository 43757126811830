import React from "react";
import bgImage from 'assets/images/company/company-img-new.jpg'
import WhoWeAre from "../components/WhoWeAre";
import OurPillars from "../components/OurPillars";
import { motion } from "motion/react"
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
// import Teams from "../components/Teams";

export default function Company() {
    const {t}= useTranslation();
    return (
        <div>
            <Helmet>
                <meta name="keywords" content="luxury real estate "/>
                <title>Downtown – Luxury Real Estate</title>
                <meta name="description"
                      content="Un Personal Advisor dedicato, una rete di broker a tua disposizione su scala mondiale. Dowtown rivoluziona il luxury real estate. Scopri di più."/>
            </Helmet>
            <div
                className="h-screen bg-cover bg-center bg-no-repeat"
                style={{backgroundImage: `url('${bgImage}')`}}
            >
                <div className="relative flex items-center justify-center h-full bg-darkGray bg-opacity-65">
                    <motion.div
                        initial={{ y: "100%", opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5, ease: "easeOut" }}
                        viewport={{ once: true}}
                        className="relative flex flex-col justify-center md:justify-end items-center h-full w-full box mt-[200px] md:mt-0">
                        <h1 className="sub-title text-center md:text-left">
                            {t('company.title_1')}
                        </h1>
                        <h1 className="title !text-[50px] ">
                            {t('company.title_2')}
                        </h1>
                    </motion.div>
                </div>
            </div>
            <WhoWeAre />
            <OurPillars />
            {/*<Teams />*/}
        </div>
    );
}
