import {useSearchParams} from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import PropertiesList from "../components/PropertiesList";
import searchIcon from "../assets/icons/search-icon.svg";
import {PropertyTypology} from "../constants/property.typology.ts";
import filterIcon from "../assets/icons/filter-icon.svg";
import PropertiesListMobile from "../components/PropertiesListMobile";
import provinces from "../assets/json/provincie_italiane.json";
import {useTranslation} from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Properties() {
    const [availableProperties, setAvailableProperties] = useState([]);
    const hasMore = useRef(true);
    const currentPage = useRef(1);
    const [searchParams] = useSearchParams();
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();
    const isFirstRender = useRef(true);
    const [country, setCountry] = useState('ITALIA');
    const [province, setProvince] = useState('');
    const [typology, setTypology] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [reference, setReference] = useState('');
    const [sort, setSort] = useState('');

    const toggleAccordion = () => setIsOpen(!isOpen);
    const url = process.env.REACT_APP_API_URL;

    // Function to fetch properties
    const fetchProperties = useCallback(async (page, params = {}, reset = false) => {
        const filteredParams = Object.entries(params)
            .filter(([_, value]) => value != null && value !== '')
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        const queryParams = new URLSearchParams({
            page: page,
            limit: 5,
            ...filteredParams,
        });

        const response = await fetch(`${url}/public-apis/fetchProperties?${queryParams.toString()}`);

        const resData = await response.json();

        if (reset) {
            setAvailableProperties([...(resData.docs || [])])
        } else {
            setAvailableProperties((prev) => [
                ...prev,
                ...(resData.docs || [])
            ])
        }

        // Verifica se ci sono altre pagine
        if (currentPage.current >= resData.totalPages) {
            hasMore.current = false;
        }
    }, [url]);


    // Fetch properties based on query params
    useEffect(() => {
        const typologyParam = searchParams.get('typology');
        const minPriceParam = searchParams.get('minPrice');
        const maxPriceParam = searchParams.get('maxPrice');
        const provinceParam = searchParams.get('province');

        if (typologyParam) setTypology(typologyParam);
        if (minPriceParam) setMinPrice(minPriceParam);
        if (maxPriceParam) setMaxPrice(maxPriceParam);
        if (provinceParam) setProvince(provinceParam);

        fetchProperties(1, {
            typology: typologyParam,
            minPrice: minPriceParam ? parseFloat(minPriceParam.replace(/\./g, '').replace(',', '.')) : null,
            maxPrice: maxPriceParam ? parseFloat(minPriceParam.replace(/\./g, '').replace(',', '.')) : null,
            province: provinceParam,
        }).then();
    }, [fetchProperties, searchParams]);

    // Fetch properties when clicking "Cerca"
    const handleSearch = () => {
        hasMore.current = true;
        currentPage.current = 1;
        fetchProperties(currentPage.current, {
            province,
            typology,
            minPrice: minPrice ? parseFloat(minPrice.replace(/\./g, '').replace(',', '.')) : null,
            maxPrice: maxPrice ? parseFloat(maxPrice.replace(/\./g, '').replace(',', '.')) : null,
            reference,
            sort,
        }, true).then();
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        handleSearch();
    }, [sort]);

    const loadMore = () => {
        currentPage.current += 1
        fetchProperties(currentPage.current, {
            province,
            typology,
            minPrice: minPrice ? parseFloat(minPrice.replace(/\./g, '').replace(',', '.')) : null,
            maxPrice: maxPrice ? parseFloat(maxPrice.replace(/\./g, '').replace(',', '.')) : null,
            reference,
            sort,
        }).then();
    };

    return (
        <div className="px-10 lg:px-[110px] 2xl:px-[300px] mt-[235px] md:mt-0 md:pt-[283px]">
            <h1 className="h1-primary">
                {t('properties.title')}
            </h1>
            <div
                className="hidden my-[87px] md:grid md:grid-cols-3 xl:grid-cols-5 md:gap-y-10 gap-x-[40px] ">
                <input
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder={t('properties.searchForm.labels.country')}
                    className="w-full"
                    disabled={true}
                />
                <select
                    value={province}
                    onChange={(e) => setProvince(e.target.value)}
                    className="w-full">
                    <option value=''>
                        {t('properties.searchForm.labels.location')}
                    </option>
                    {Object.entries(provinces).map(([key, province]) => (
                        <option key={key} value={province.code}>
                            {province.name}
                        </option>
                    ))}
                </select>
                <select value={typology} onChange={(e) => setTypology(e.target.value)} className="w-full">
                    <option value=''>
                        {t('properties.searchForm.labels.typology')}
                    </option>
                    {Object.entries(PropertyTypology).map(([key, typology]) => (
                        <option key={key} value={typology}>
                            {t(`property.typologies.${typology}`)}
                        </option>
                    ))}
                </select>
                <div className="flex items-end">
                    <input
                        type="text"
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                        placeholder={t('properties.searchForm.labels.minPrice')}
                        className="w-full"
                    />
                    <span className={'font-raleway md:text-[16px] text-secondary border-b pb-[15px]'}>€</span>
                </div>
                <div className="flex items-end">
                    <input
                        type="text"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                        placeholder={t('properties.searchForm.labels.maxPrice')}
                        className="w-full"
                    />
                    <span className={'font-raleway md:text-[16px] text-secondary border-b pb-[15px]'}>€</span>
                </div>
                <input
                    type="text"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                    placeholder={t('properties.searchForm.labels.codeReference')}
                    className="w-full"
                    />
                    <div className="grid grid-cols-subgrid md:col-span-3 xl:col-span-4">
                        <button className="btn-form md:!text-[16px]" onClick={handleSearch}>
                            <div className="flex justify-between">
                                <span className="me-5">{t('properties.searchForm.find')}</span>
                                <img src={searchIcon} alt="search"/>
                            </div>
                        </button>
                        <select
                            className="sort-select md:col-start-3 xl:col-start-4"
                            value={sort} onChange={(e) => setSort(e.target.value)}>
                            <option value="">
                                {t('properties.searchForm.sort.label')}
                            </option>
                            <option value="price">
                                {t('properties.searchForm.sort.values.priceAsc')}
                            </option>
                            <option value="-price">
                                {t('properties.searchForm.sort.values.priceDes')}
                            </option>
                            <option value="-createdAt">
                                {t('properties.searchForm.sort.values.date')}
                            </option>
                        </select>
                    </div>
                </div>
                <div className="md:hidden w-full mt-[180px]">
                    <div className="py-[20px]">
                        <button
                            onClick={toggleAccordion}
                            className="font-raleway font-light text-[16px] tracking-[1.6px] uppercase w-full text-gray"
                        >
                            <div className={`flex ${isOpen ? 'justify-end' : 'justify-between'} border-b pb-[19px]`}>
                            <span className="font-light">{
                                isOpen ? t('properties.closeFilters') : t('properties.filters')}</span>
                                <img className={isOpen ? 'hidden' : ''} src={isOpen ? '' : filterIcon} alt="search"/>
                            </div>
                        </button>

                        {isOpen && (
                            <div className="mt-[63px]">
                                <input
                                    type="text"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    placeholder={t('properties.searchForm.labels.country')}
                                    className="w-full"
                                    disabled={true}
                                />
                                <select
                                    value={province}
                                    onChange={(e) => setProvince(e.target.value)}
                                    className="w-full mt-[42px]">
                                    <option value=''>
                                        {t('properties.searchForm.labels.location')}
                                    </option>
                                    {Object.entries(provinces).map(([key, province]) => (
                                        <option key={key} value={province.code}>
                                            {province.name}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    value={typology}
                                    onChange={(e) => setTypology(e.target.value)}
                                    className="w-full mt-[42px]"
                                >
                                    <option value={null}>
                                        {t('properties.searchForm.labels.typology')}
                                    </option>
                                    {Object.entries(PropertyTypology).map(([key, typology]) => (
                                        <option key={key} value={typology}>
                                            {t(`property.typologies.${typology}`)}
                                        </option>
                                    ))}
                                </select>
                                <div className="flex items-end mt-[42px]">
                                <input
                                        type="text"
                                        value={minPrice}
                                        onChange={(e) => setMinPrice(e.target.value)}
                                        placeholder={t('properties.searchForm.labels.minPrice')}
                                        className="w-full"
                                    />
                                    <span
                                        className={'font-raleway text-[18px] text-secondary border-b pb-[15px]'}>€</span>
                                </div>
                                <div className="flex items-end mt-[42px]">
                                    <input
                                        type="text"
                                        value={maxPrice}
                                        onChange={(e) => setMaxPrice(e.target.value)}
                                        placeholder={t('properties.searchForm.labels.maxPrice')}
                                        className="w-full"
                                    />
                                    <span
                                        className={'font-raleway text-[18px] text-secondary border-b pb-[15px]'}>€</span>
                                </div>
                                <button
                                    onClick={handleSearch}
                                    className="btn-form mt-[42px] w-full after:border-0"
                                >
                                    <div className="flex justify-between border-b pb-[15px]">
                                        <span>
                                            {t('properties.searchForm.find')}
                                        </span>
                                        <img src={searchIcon} alt="search"/>
                                    </div>
                                </button>
                            </div>
                        )}
                        <select
                            className="sort-select mt-[42px] !w-full"
                            value={sort} onChange={(e) => setSort(e.target.value)}>
                            <option value="">{t('properties.searchForm.sort.label')}</option>
                            <option value="price">
                                {t('properties.searchForm.sort.values.priceAsc')}
                            </option>
                            <option value="-price">
                                {t('properties.searchForm.sort.values.priceDes')}
                            </option>
                            <option value="-createdAt">
                                {t('properties.searchForm.sort.values.date')}
                            </option>
                        </select>
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={availableProperties.length}
                    next={loadMore}
                    hasMore={hasMore.current}
                    loader={''}
                    endMessage={''}
                >
                    <div className="hidden md:block">
                        <PropertiesList properties={availableProperties}/>
                    </div>
                    <div className="md:hidden">
                        <PropertiesListMobile properties={availableProperties} handleSearch={handleSearch}/>
                    </div>
                </InfiniteScroll>
            </div>
            )
            }
