import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import headerLogo from "../assets/logos/logo-header.svg";
import hamburgerIcon from "../assets/icons/menu-icon.svg";
import SidebarMenu from "./SidebarMenu";
import {useTranslation} from "react-i18next";

export default function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {t} = useTranslation();

    const isActive = (path) => location.pathname === path;

    const onLogoClick = () => {
        navigate(`/`);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div>
            <header className="absolute top-[40px] flex justify-center z-10 w-full px-10 md:px-0">
                <nav className="container flex justify-between items-center md:px-2">
                    <img
                        src={headerLogo}
                        alt="Header logo"
                        className="cursor-pointer h-11 md:h-6 lg:h-[30px] xl:h-[50px]"
                        onClick={onLogoClick}
                    />

                    <button
                        className="md:hidden flex items-center justify-center"
                        onClick={toggleMenu}
                    >
                        <img
                            src={isMenuOpen ? hamburgerIcon : hamburgerIcon}
                            alt="Menu"
                            className="h-6 w-6"
                        />
                    </button>

                    {/* Links */}
                    <div
                        className={`hidden mb:block absolute md:static top-16 left-0 w-full md:w-auto bg-darkGray md:bg-transparent md:p-0 space-y-4 md:space-y-0 md:flex md:items-center md:space-x-3 lg:space-x-12`}
                    >
                        <Link
                            to="/about-us"
                            className={`hover:font-black ${
                                isActive("/about-us") ? "font-black" : "font-normal"
                            }`}
                        >
                            {t('header.company')}
                        </Link>
                        <Link
                            to="/properties"
                            className={`hover:font-black ${
                                isActive("/properties") ? "font-black" : "font-normal"
                            }`}
                        >
                            {t('header.property')}
                        </Link>
                        <Link
                            to="/off-market-properties"
                            className={`hover:font-black ${
                                isActive("/off-market-properties") ? "font-black" : "font-normal"
                            }`}
                        >
                            {t('header.offMarketProperty')}
                        </Link>
                        <Link
                            to="/property-finding"
                            className={`hover:font-black ${
                                isActive("/property-finding") ? "font-black" : "font-normal"
                            }`}
                        >
                            {t('header.propertyFinding')}
                        </Link>
                        <Link
                            to="/contacts"
                            className={`hover:font-black ${
                                isActive("/contacts") ? "font-black" : "font-normal"
                            }`}
                        >
                            {t('header.contacts')}
                        </Link>
                    </div>
                </nav>
            </header>
            <SidebarMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        </div>
    );
}
