export const PropertyTypology = {
    APARTMENT: 'apartment',
    PENTHOUSE: 'penthouse',
    FARMHOUSE: 'farmhouse',
    COTTAGE: 'cottage',
    CASTLE: 'castle',
    CHALET: 'chalet',
    COTTAGE_HOUSE: 'cottageHouse',
    DWELLING: 'dwelling',
    ISLAND: 'island',
    LOFT: 'loft',
    ESTATE: 'estate',
    VILLA: 'villa',
}