import React from "react";
import VideoBackground from "../components/VideoBackground";
import HomeSectionGallery from "../components/HomeSectionGallery";
import HomeSectionAboutUs from "../components/HomeSectionAboutUs";
import HomePropertySearchFormMobile from "../components/HomePropertySearchFormMobile";
import {Helmet} from "react-helmet-async";

function Home() {
    return (
        <div>
            <Helmet>
                <meta name="keywords" content="downtown"/>
                <title>Downtown – Live the luxury</title>
                <meta name="description"
                      content="Lusso è trasformare i desideri in realtà. Downtown è specialista nel farlo: immobili da sogno, per ogni concetto di abitare, in luoghi incantevoli. Lasciati guidare."/>
            </Helmet>
            <VideoBackground/>
            <div className="md:hidden">
                <HomePropertySearchFormMobile/>
            </div>
            <HomeSectionGallery/>
            <HomeSectionAboutUs/>
        </div>
    );
}

export default Home;
