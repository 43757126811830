import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import shareIcon from "../assets/icons/share-icon.svg";
import PropertySectionTab from "../components/PropertySectionTab";
import PropertySectionContact from "../components/PropertySectionContact";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

export default function OffMarketProperty() {
    const {propertyId} = useParams();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const url = process.env.REACT_APP_API_URL;
    const { t } = useTranslation();

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Downtown – Off Market Property',
                text: 'Anche nel mercato del lusso ci sono proprietà ancora più esclusive di altre. Le abbiamo raccolte nella sezione Off Market Property. Scoprile.',
                url: window.location.href,
            })
                .then()
                .catch((error) => console.error('Errore durante la condivisione:', error));
        } else {
            console.log('La condivisione non è supportata in questo browser.');
        }
    };

    useEffect(() => {
        async function getProperty() {
            const response = await fetch(`${url}/public-apis/property/${propertyId}`);
            const resData = await response.json();
            setProperty(resData);
            setLoading(false);
        }
        getProperty();
    }, [propertyId, url]);

    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV === "production" && window.fbq) {
            window.fbq("track", "ViewContent", {
                content_name: `Property ${propertyId}`,
                content_ids: [propertyId],
                content_type: "property",
            });
        }
    }, [propertyId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Helmet>
                <title>{`Downtown - ${t(`property.typologies.${property?.propertyTypology}`)} - ${property?.city}`}</title>
                <meta property="og:title" content={`Downtown - ${t(`property.typologies.${property?.propertyTypology}`)} - ${property?.city}`}/>
                <meta property="og:description"
                      content='Scopri le migliori case di lusso in vendita con Downtown. Ville esclusive, attici panoramici e residenze uniche in location prestigiose. Trova la tua casa da sogno oggi stesso.'/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:type" content="website"/>
            </Helmet>
            <div className="relative h-full flex items-end mt-[200px] md:mt-[355px]">
                <div className="w-full px-10 xl:px-[300px] hidden md:block">
                    <div className="flex justify-between items-end">
                        <div className="flex flex-col">
                            <h3 className={'h3'}>
                                {t(`property.typologies.${property?.propertyTypology}`)}
                            </h3>
                            <span
                                className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase">{property?.city}</span>
                        </div>
                        <div className="flex">
                            <span
                                className={'font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase flex'}>{property?.reference}
                            </span>
                            <button
                                className="font-raleway text-left text-[18px] tracking-[1.8px] text-gray uppercase flex" onClick={handleShare}>
                                <img className="ms-4 mt-1" src={shareIcon} alt="share"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <PropertySectionTab property={property}/>
            <PropertySectionContact reference={property?.reference} />
        </div>
    )
}
