import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import 'react-phone-number-input/style.css';
import App from "./App";
import './i18n';

const ExternalScripts = () => {
    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV === 'production') {
            // Script per Iubenda
            const iubendaScript = document.createElement('script');
            iubendaScript.type = 'text/javascript';
            iubendaScript.innerHTML = `
                var _iub = _iub || [];
                _iub.csConfiguration = {
                    "askConsentAtCookiePolicyUpdate": true,
                    "floatingPreferencesButtonDisplay": "bottom-right",
                    "perPurposeConsent": true,
                    "siteId": 3206987,
                    "whitelabel": false,
                    "cookiePolicyId": 36869528,
                    "lang": "it",
                    "banner": {
                        "acceptButtonColor": "#76777A",
                        "acceptButtonDisplay": true,
                        "backgroundOverlay": true,
                        "closeButtonDisplay": false,
                        "customizeButtonDisplay": true,
                        "explicitWithdrawal": true,
                        "listPurposes": true,
                        "logo": "https://downtown.it/wp-content/uploads/2023/05/Logo.svg",
                        "position": "float-bottom-center",
                        "rejectButtonColor": "#76777A",
                        "rejectButtonDisplay": true
                    }
                };
            `;
            document.head.appendChild(iubendaScript);

            const iubendaSyncScript = document.createElement('script');
            iubendaSyncScript.type = 'text/javascript';
            iubendaSyncScript.src = '//cs.iubenda.com/sync/3206987.js';
            document.head.appendChild(iubendaSyncScript);

            const iubendaCS = document.createElement('script');
            iubendaCS.type = 'text/javascript';
            iubendaCS.src = '//cdn.iubenda.com/cs/iubenda_cs.js';
            iubendaCS.charset = 'UTF-8';
            iubendaCS.async = true;
            document.head.appendChild(iubendaCS);

            // Google Tag Manager
            const gtmScript = document.createElement('script');
            gtmScript.innerHTML = `
                (function(w,d,s,l,i){
                    w[l]=w[l]||[];
                    w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                    f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-PXT28MC');
            `;
            document.head.appendChild(gtmScript);

            const gtmNoscript = document.createElement('noscript');
            gtmNoscript.innerHTML = `
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PXT28MC"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `;
            document.body.insertBefore(gtmNoscript, document.body.firstChild);

            // Meta Pixel
            const pixelScript = document.createElement('script');
            pixelScript.innerHTML = `
                !function(f,b,e,v,n,t,s){
                    if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '834799301411162');
                fbq('track', 'PageView');
            `;
            pixelScript.async = true;
            document.head.appendChild(pixelScript);

            // Aggiunta del noscript per il Meta Pixel
            const noscriptPixel = document.createElement('noscript');
            noscriptPixel.innerHTML = `
                <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=834799301411162&ev=PageView&noscript=1"
                />
            `;
            document.body.appendChild(noscriptPixel);

            // Cleanup per evitare duplicati
            return () => {
                const pixelScriptElement = document.querySelector('script[src="https://connect.facebook.net/en_US/fbevents.js"]');
                if (pixelScriptElement) {
                    pixelScriptElement.remove();
                }
                const noscriptPixelElement = document.querySelector('img[src^="https://www.facebook.com/tr?id="]');
                if (noscriptPixelElement) {
                    noscriptPixelElement.remove();
                }
            };
        }
    }, []);

    return null;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <ExternalScripts />
        <App />
    </>
);
