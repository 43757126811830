import {useTranslation} from "react-i18next";

export function LanguageSwitch() {
    const { i18n } = useTranslation();

    const styles = {
        active: {
            fontWeight: 'bolder',
        },
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng).then();
    };

    return (
        <div className="hidden md:grid text-secondary grid-cols-2 gap-x-[45px] font-raleway text-[18px] tracking-[2px] absolute md:right-[0px] top-[45%] z-[2] rotate-90">
            <button
                style={{
                    ...(i18n.language === 'it' ? styles.active : {}),
                }}
                className={'font-thin hover:font-bold'}
                onClick={() => changeLanguage('it')}
            >
                ITA
            </button>
            <button
                style={{
                    ...(i18n.language === 'en' ? styles.active : {}),
                }}
                className={'font-thin hover:font-bold'}
                onClick={() => changeLanguage('en')}
            >
                ENG
            </button>
        </div>
    );
}